<template>
  <div style="color: #000;height: 100%;">
    <van-nav-bar title="提示" left-arrow :fixed="true" :z-index="999" @click-left="back"></van-nav-bar>
    <div style="padding-top:116px; text-align: center;">
      <div class="tishi">您已注册过二手车车辆，是否替换当前车辆？</div>
      <div>
        <van-button type="danger" @click="back" size="small">取消</van-button>
        <van-button type="primary" @click="toqueding" size="small">确定</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { changecar } from "../../api/check.js";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  components: {},
  // mixins: [preventBack], //注入
  data() {
    return {
      list: {},
      nid: this.$route.query.nid,
      oid: this.$route.query.oid
    };
  },
  methods: {
    toqueding() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });

      const data = {
        nid: this.nid,
        oid: this.oid
      };
      changecar(data)
        .then(e => {
          if (e.code == 200) {
            this.$toast.success("替换成功！");
          }
        })
        .catch(() => loading.clear());
    },
    onLoads() {
      // this.page++;
      this.onLoad();
    },

    back() {
      WeixinJSBridge.call("closeWindow");
      // this.$router.go(-1);
    }
  },
  created() {},
  mounted() {
    // this.backziding();
  }
};
</script>

<style lang="less" scoped>
.tishi {
  color: red;
  line-height: 30px;
  font-size: 16px;
}
.van-button {
  width: 100px;
  margin: 0 10px;
  margin-top: 100px;
}
</style>